import React from "react"
import PropTypes from "prop-types"
import Container from "react-bootstrap/Container"
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Scroller from "./Scroller";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { AuthState } from '@aws-amplify/ui-components'
import { Auth } from "aws-amplify";
import classNames from "classnames";

export default class Header extends React.Component {
  constructor(props) {
    super(props)
    Scroller.handleAnchorScroll = Scroller.handleAnchorScroll.bind(this)
    this.signOut = this.signOut.bind(this)
  }

  async signOut() {
    await Auth.signOut();
    if(this.props.onSignOut) {
      this.props.onSignOut();
    }
  }
  
  render() {
    return (
      <header className={classNames(this.props.transparentNav ? "transparentNav" : "", this.props.darkNav ? "darkNav" : "")}>
        <Container>
          {this.props.transparentNav && 
            <Navbar fixed={this.props.transparentNav ? "top" : ""} variant="dark" expand="lg">
              <Container> 
                <Navbar.Brand><Link to="/"><StaticImage height={60} placeholder="none" src="../assets/img/oew-logo-text.svg" alt="On Eagles Wings"></StaticImage></Link></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="ml-auto">
                    <Link to="/events" className="nav-link">Events</Link>
                    <Link to="/warrior-worship" className="nav-link">Warrior Worship</Link>
                    <Link to="/resources" className="nav-link">Resources</Link>
                    <Link to="/leaders" className="nav-link">Leaders</Link>
                    <Link to="/about" className="nav-link">About</Link>
                    {this.props.authState===AuthState.SignedIn && <Link to="/leaders" onClick={this.signOut} className="nav-link">Sign Out</Link>}
                  </Nav>
                </Navbar.Collapse>
              </Container> 
            </Navbar>
          }          
          {!this.props.transparentNav && 
            <Navbar fixed={this.props.transparentNav ? "top" : ""} variant="dark" expand="lg">
              <Navbar.Brand><Link to="/"><StaticImage height={60} placeholder="none" src="../assets/img/oew-logo-text.svg" alt="On Eagles Wings"></StaticImage></Link></Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                  <Link to="/events" className="nav-link">Events</Link>
                  <Link to="/warrior-worship" className="nav-link">Warrior Worship</Link>
                  <Link to="/resources" className="nav-link">Resources</Link>
                  <Link to="/leaders" className="nav-link">Leaders</Link>
                  <Link to="/about" className="nav-link">About</Link>
                  {this.props.authState===AuthState.SignedIn && <Link to="/leaders" onClick={this.signOut} className="nav-link">Sign Out</Link>}
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          }
        </Container>
      </header>
    )
  }
}

Header.propTypes = {
  transparentNav: PropTypes.bool,
  darkNav: PropTypes.bool,
  authState: PropTypes.string,
  onSignOut: PropTypes.func
}