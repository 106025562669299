import { AuthState } from '@aws-amplify/ui-components'

export const setCurrentAuthState = (nextAuthState) => {
  if(typeof window !== `undefined`) {
    window.localStorage.oewUserState = nextAuthState;
  }
}

export const getCurrentAuthState = () => {
  if (typeof window === `undefined` || !window.localStorage.oewUserState) {
    return AuthState.SignedOut;
  } else {
    return window.localStorage.oewUserState;
  }
}

export const setWLSUser = (wlsUser) => {
  if(typeof window !== `undefined`) {
    if(typeof wlsUser === 'undefined') {
      window.localStorage.removeItem('wlsUser');
    } else {
      window.localStorage.wlsUser = JSON.stringify(wlsUser);
    }
  }
}

export const getWLSUser = () => {
  if (typeof window === `undefined` || typeof window.localStorage.wlsUser === 'undefined') {
    return { firstName: null, lastName: null, email: null, tribe: null, optin: true};
  } else {
    return JSON.parse(window.localStorage.wlsUser);
  }
}

export const getFeaturedItems = (queryResult) => {
  let nowStr = dateTimeToStr(new Date());
  let sets = (queryResult && queryResult.edges ? queryResult.edges : []).map(({node}) => node);
  let filteredSets = sets.filter(function(item) { return (dateTimeToStr(item.visibleDate) <= nowStr && (!item.hiddenDate || dateTimeToStr(item.hiddenDate) > nowStr));});
  return (filteredSets.length > 0 && filteredSets[0].items && filteredSets[0].items.length > 0) ? filteredSets[0].items : undefined;
}

export const getFeaturedItem = (queryResult) => {
  let items = getFeaturedItems(queryResult);
  return (items && items.length > 0 ? items[0] : undefined);
}

export const getTopicsFromResources = (resources) => {
  var topicHitNames = [];
  var topicHitObjects = [];
  resources.forEach(resource => {
    if(resource.topics) {
      resource.topics.forEach(topic => {
        if(topicHitNames.includes(topic.name)===false) {
          topicHitNames.push(topic.name);
          topicHitObjects.push(topic);
        }
      });
    }
  });
  topicHitObjects.sort(sortByName);
  return topicHitObjects;
}

export const getLinkForContent = (content, isBonus) => {
  let pathMap = {
    "ministryEvent" : (content.eventPage ? content.eventPage : "/events/" + content.slug),
    "topic" : (isBonus ? "/resources/bonus-topic-" : "/resources/topic-") + content.slug,
    "videoResource" : "/resources/" + content.slug,
    "pdfResource" : content.file ? (content.file.file ? content.file.file.url : undefined) : undefined,
    "warriorWorshipSong" : "/warrior-worship/" + content.slug,
  }
  let sys = content ? content.sys : undefined;
  let contentType = (sys && sys.contentType && sys.contentType.sys) ? sys.contentType.sys.id : undefined;
  if(contentType && pathMap[contentType]) {
    return pathMap[contentType];
  } else {
    return undefined;
  }
}

export const getPathForType = (content) => {
  let sys = content ? content.sys : undefined;
  let contentType = (sys && sys.contentType && sys.contentType.sys) ? sys.contentType.sys.id : undefined;
  let contentTypePath = 
      contentType === 'warriorWorshipSong' ? '/warrior-worship/' 
      : (contentType === 'videoResource' ? '/resources/' 
      : (contentType === 'topic' ? '/resources/topic-' 
      : '/'));
  return (contentTypePath ? contentTypePath : '/');
}

export const loadScriptPromise = (scriptPath, uniqueId) => {
  if(typeof window === 'undefined') {
    return new Promise((resolve, reject) => { resolve() });
  } else {
    if(typeof window[uniqueId] === 'undefined') {
      window[uniqueId] = new Promise((resolve, reject) => {
        const script = document.createElement('script');
        document.body.appendChild(script);
        script.onload = resolve;
        script.onerror = reject;
        script.async = true;
        script.crossOrigin = undefined;
        script.src = scriptPath;
      });
    }
    return window[uniqueId];
  }
}

export const sortByName = (a, b) => {
  let aName = a.name ? a.name.toUpperCase() : null;
  let bName = b.name ? b.name.toUpperCase() : null;
  if (aName < bName) {
    return -1;
  }
  if (aName > bName) {
    return 1;
  }
  return 0;      
}

export const getParameterByName = (name) => {
  if(typeof window === 'undefined') {
    return undefined;
  } else {
    let url = window.location.href;
    name = name.replace(/[[\]]/g, "\\$&");
    let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }
}

function padDatePart(n) { return (n<10 ? "0" : "") + n; }

export const parseDateStr = (str, useLocal) => {
  if(!str) {
    return undefined;
  }
  var d = new Date();
  var regexp = /^([\d]{4})-?([\d]{2})-?([\d]{2})(T([\d]{2}):?([\d]{2}):?([\d]{2})(.([\d]{3}))?(Z|([+-])([\d]{2}):?([\d]{2})))?$/;
  var m = str.match(regexp);
  if (m) {
    d = new Date(0);
    if(useLocal) {
      d.setFullYear(parseInt(m[1], 10));
      d.setDate(parseInt(m[3], 10));
      d.setMonth(parseInt(m[2], 10) - 1);
      d.setHours(0);
      d.setMinutes(0);
      d.setSeconds(0);
      d.setMilliseconds(0);
    } else {
      d.setUTCFullYear(parseInt(m[1], 10));
      d.setUTCDate(parseInt(m[3], 10));
      d.setUTCMonth(parseInt(m[2], 10) - 1);
      d.setUTCHours(0);
      d.setUTCMinutes(0);
      d.setUTCSeconds(0);
      d.setUTCMilliseconds(0);
    }
    return d;
  } else {
    throw new Error("Invalid date format is specified : " + str);
  }
}

export const parseDateTimeStr = (str) => {
  if(!str) {
    return undefined;
  }
  var d = new Date();
  var regexp = /^([\d]{4})-?([\d]{2})-?([\d]{2})(T([\d]{2}):?([\d]{2})(:?([\d]{2})(.([\d]{3}))?)?(Z|([+-])([\d]{2}):?([\d]{2})))?$/;
  var m = str.match(regexp);
  if (m) {
    d = new Date(0);
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);

    d.setUTCFullYear(parseInt(m[1], 10));
    d.setUTCDate(parseInt(m[3], 10));
    d.setUTCMonth(parseInt(m[2], 10) - 1);
    if(m.length > 7) {
      d.setUTCHours(parseInt(m[5], 10));
      d.setUTCMinutes(parseInt(m[6], 10));
      if(m.length > 8 && m[8]) {
        d.setUTCSeconds(parseInt(m[8], 10));
      }
      if(m.length > 10 && m[10]) {
        d.setUTCMilliseconds(parseInt(m[10], 10));
      }
    }

    var offsetMilli = 0;
    if(m[m.length - 3] === '-') {
      offsetMilli = 0 - (Number.parseInt(m[m.length - 2]) * 60 + Number.parseInt(m[m.length - 1])) * 60 * 1000;
    }
    if(m[m.length - 3] === '+') {
      offsetMilli = (Number.parseInt(m[m.length - 2]) * 60 + Number.parseInt(m[m.length - 1])) * 60 * 1000;
    }
    d.setTime(d.getTime() - offsetMilli);

    return d;
  } else {
    throw new Error("Invalid date format is specified : " + str);
  }
}  

export const dateToStr = (dateObj) => {
  if(typeof dateObj === 'string') {
    dateObj = parseDateStr(dateObj, true);
  }
  if(!dateObj) {
    return undefined;
  }
  var yyyy = dateObj.getUTCFullYear();
  var mm = dateObj.getUTCMonth()+1;
  var dd = dateObj.getUTCDate();
  return "" + yyyy + "-" + padDatePart(mm) + "-" + padDatePart(dd);
}

export const dateTimeToStr = (dateObj, offsetMilliseconds) => {
  if(typeof dateObj === 'string') {
    dateObj = parseDateTimeStr(dateObj);
  }
  if(!dateObj) {
    return undefined;
  }
  if(offsetMilliseconds) {
    dateObj.setTime(dateObj.getTime() + offsetMilliseconds);
  }
  
  var yyyy = dateObj.getUTCFullYear();
  var mm = dateObj.getUTCMonth()+1;
  var dd = dateObj.getUTCDate();
  var hh = dateObj.getUTCHours();
  var mi = dateObj.getUTCMinutes();
  var ss = dateObj.getUTCSeconds();

  return "" + yyyy + "-" + padDatePart(mm) + "-" + padDatePart(dd) + "T" + padDatePart(hh) + ":" + padDatePart(mi) + ":" + padDatePart(ss) + "Z";
}

