import React from "react"
import PropTypes from "prop-types"
import Container from "react-bootstrap/Container"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from "classnames";

export default class Section extends React.Component {
  render() {
    let rowClasses = classNames(
      (typeof this.props.centerHorizontally === 'undefined' || this.props.centerHorizontally === true) ? "justify-content-center" : null,
      (this.props.centerVertically === true) ? "align-items-center" : null
    );
    return (
      <section className={this.props.className}>
        { this.props.useContainer !== false && <Container>
          { this.props.useRow !== false && <Row className={rowClasses}>
            { this.props.useCol !== false && <Col>{this.props.children}</Col> }
            { this.props.useCol === false && this.props.children }
          </Row> }
          { this.props.useRow === false && this.props.children }
        </Container> }
        { this.props.useContainer === false && this.props.children }
      </section>
    )
  }
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  useContainer: PropTypes.bool,
  useRow: PropTypes.bool,
  useCol: PropTypes.bool,
  centerHorizontally: PropTypes.bool,
  centerVertically: PropTypes.bool,
}