import React from "react"
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from "classnames";

export default class Footer extends React.Component {
  render() {
    return (
      <footer className="py-5">
        <Container>
          <Row>
            <Col xs={12} sm={8} md={6} className="pb-4">
              <h4>Contact Us</h4>
              <div className="py-1">
                We want to hear from you!
              </div>
              <div className="py-1"><i className={classNames("fas", "fa-envelope", "footerIcon")}></i> <a href="mailto:contact@oneagleswings.com">contact@oneagleswings.com</a></div>
              <div className="py-1"><i className={classNames("fas", "fa-mobile-alt", "footerIcon")} style={{padding: '4px 6px 4px 6px'}}></i> <a href="tel:8886825483">(888) 682-5483</a></div>
              <div className="py-1"><i className={classNames("fab", "fa-facebook", "footerIcon")}></i> <a href="https://www.facebook.com/oewofficial" target="_blank" rel="noreferrer">facebook.com/oewofficial</a></div>
              <div className="py-1"><i className={classNames("fab", "fa-instagram", "footerIcon")}></i> <a href="https://www.instagram.com/oewofficial/" target="_blank" rel="noreferrer">instagram.com/oewofficial</a></div>
              <div className="py-1"><i className={classNames("fab", "fa-youtube", "footerIcon")}></i> <a href="https://www.youtube.com/warriorleadership" target="_blank" rel="noreferrer">youtube.com/warriorleadership</a></div>
            </Col>
            <Col xs={12} sm={8} md={6}>
              <h4>Suicidal? Need to talk?</h4>
              <div className="py-1">
                Chat with an OEW partner
              </div>
              <div className="py-1"><i className={classNames("fas", "fa-comment-dots", "footerIcon")}></i> <a href="https://www.groundwire.net/">Online Chat</a></div>
              <div className="py-1"><i className={classNames("fas", "fa-mobile-alt", "footerIcon")} style={{padding: '4px 6px 4px 6px'}}></i> <a href="tel:8002738255">Call the Hotline 800-273-8255</a></div>
            </Col>
            <Col xs={12} sm={8} className="pt-3 pb-1">
              Copyright &copy; 2020 - All Rights Reserved - On Eagles' Wings&reg;.
            </Col>
          </Row>
        </Container>
      </footer>
    )
  }
}
