import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames";

export default class Headline extends React.Component {
  render() {
    let holderClass = classNames("headline-holder pb-2 d-flex ", this.props.className, this.props.center === true ? "justify-content-center" : "justify-content-start");
    return (
      <div className={holderClass}>
        <div>
          <h1>{this.props.children}</h1>
        </div>
      </div>
    )
  }
}

Headline.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  center: PropTypes.bool
}