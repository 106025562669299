import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames";
import Header from "./Header"
import Container from "react-bootstrap/Container"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Footer from "./Footer"
import { Auth } from "aws-amplify";
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'
import { setCurrentAuthState } from "../services/utility"
import { AmplifyLoadingSpinner } from "@aws-amplify/ui-react"
import Section from "./Section"
import "../scss/style.scss"

const getScrollNode = (element) => {
  return element.ownerDocument.scrollingElement || element.ownerDocument.documentElement
}

const isScrolled = (element) => {
  const scrollNode = getScrollNode(element)
  return scrollNode.scrollTop > 0
}

export default class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.siteContainer = React.createRef()
    setCurrentAuthState(AuthState.Loading);
    this.state = {
      scrolled: false,
      authState: AuthState.Loading,
      firstName: null
    }
    this.handleScroll = this.handleScroll.bind(this);
    this.onSignOut = this.onSignOut.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
    const element = this.siteContainer.current
    this.setState({
      scrolled: isScrolled(element),
    })
    onAuthUIStateChange((nextAuthState, authData) => {
      setCurrentAuthState(nextAuthState);
      this.setState({
        authState: nextAuthState,
        firstName: authData && authData.attributes ? authData.attributes.given_name : null
      });
      if(this.props.onAuthStateChanged) {
        this.props.onAuthStateChanged();
      }
    });
    Auth.currentAuthenticatedUser().then(user => {
      setCurrentAuthState(AuthState.SignedIn);
      this.setState({
        authState: AuthState.SignedIn,
        firstName: user && user.attributes ? user.attributes.given_name : null
      });
      if(this.props.onAuthStateChanged) {
        this.props.onAuthStateChanged();
      }
    }).catch(err => {
      this.onSignOut();
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll() {
    const element = this.siteContainer.current
    this.setState({
      scrolled: isScrolled(element),
    })
  }

  onSignOut() {
    setCurrentAuthState(AuthState.SignedOut);
    this.setState({
      authState: AuthState.SignedOut,
      firstName: null
    });
    if(this.props.onAuthStateChanged) {
      this.props.onAuthStateChanged();
    }
  }

  render() {
    let scrollClass = (this.state.scrolled ? "navbar-scrolled" : "");
    let ensureSignedIn = (typeof this.props.ensureSignedIn !== 'undefined' && this.props.ensureSignedIn === true);
    if(ensureSignedIn && this.state.authState!==AuthState.Loading && this.state.authState!==AuthState.SignedIn){
      if (typeof window !== 'undefined') {
        window.location.href = '/leaders/signin';
      }
      return null;
    } else {
      return (
        <div className={classNames(scrollClass, this.props.className)} ref={this.siteContainer} id="page-top" style={{overflow:"hidden"}}>
          <Container fluid>
            <Row style={{margin: 0}}>
              <Col style={{padding: 0}}>
                <Header transparentNav={this.props.transparentNav} darkNav={this.props.darkNav} authState={this.state.authState} onSignOut={this.onSignOut} ></Header>
                {this.state.authState===AuthState.Loading && <main><Section className="white text-center"><div className="pt-5" style={{ transform: "scale(2)"}}><AmplifyLoadingSpinner/></div></Section></main> }
                {this.state.authState!==AuthState.Loading && <main>{this.props.children}</main> }
                <Footer></Footer>
              </Col>
            </Row>
          </Container>
        </div>
      )
    }
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  transparentNav: PropTypes.bool,
  darkNav: PropTypes.bool,
  ensureSignedIn: PropTypes.bool,
  onAuthStateChanged: PropTypes.func
}